<template>
  <div class="container">
    <el-container>
      <el-header>
        <div class="left">
          <div>欢迎你，{{ Admin }}</div>
        </div>
        <div class="right">
          <div class="btn-rank" @click="handleFullScreen">
            <el-tooltip
              effect="dark"
              :content="fullscreen ? `取消全屏` : `全屏`"
              placement="bottom"
            >
              <i class="el-icon-rank"></i>
            </el-tooltip>
          </div>
          <div class="btnout">
            <i class="el-icon-user-solid user"></i>
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                用户<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="logOut">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-main class="main">
        <div class="mainCenter">
          <div class="title">
            <p>为了系统安全，本系统对登陆密码进行了升级，请修改密码！</p>
            <p>修改密码</p>
          </div>
          <el-form
            :model="modifyPassword"
            :rules="FormRules"
            ref="addRef"
            label-width="150px"
            class="demo-ruleForm"
            :label-position="labelPosition"
          >
            <el-form-item label="请输入新密码" prop="password1">
              <el-input
                v-model="modifyPassword.password1"
                placeholder="请输入密码"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item label="请再次输入新密码" prop="password2">
              <el-input
                v-model="modifyPassword.password2"
                placeholder="请再次输入新密码"
                show-password
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="logOut">取 消</el-button>
            <el-button type="primary" @click="addClick" :loading="loadingBtn"
              >确 定</el-button
            >
          </span>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { login, logout } from '../api/login.js'
import { getRole } from '@/api/coupon.js'
import { getCurrentSysAdmin, changePassword } from '@/api/common.js'
export default {
  // 组件名称
  name: 'changePassword',
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    var validatePass1 = (rule, value, callback) => {
      let result
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        var express =
          /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
        result = express.test(value) // 满足条件时候 正则结果是true
        if (result) {
          callback()
        } else {
          callback(new Error('最少8个字符，需包含大、小写字母、特殊字符和数字'))
        }
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.modifyPassword.password1) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      loadingBtn: false,
      labelPosition: 'left',
      Admin: undefined,
      fullscreen: false,
      modifyPassword: {
        password1: '',
        password2: '',
      },
      query: {
        checked: false,
        username: '',
        password: '',
        verifyCode: '', // 验证码
        verifyCodeKey: 'yzm' + new Date().getTime(), // 随机字符串
      },
      FormRules: {
        password1: [
          { required: true, validator: validatePass1, trigger: 'blur' },
        ],
        password2: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      },
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    getCurrentSysAdmin() {
      // 查询登入用户信息
      getCurrentSysAdmin().then((res) => {
        if (res.data.code === '200') {
          this.Admin = res.data.data.username
          this.query.username = res.data.data.username
        }
      })
    },
    logOut() {
      logout().then(() => {
        // 退出登入
        this.$store.commit('setactivetagTitle', 'Welcome')
        this.$store.commit('deleteTab', 0)
        this.$cookies.remove('setifuserweakPassword')
        this.$cookies.remove('activePathypt')
        this.$cookies.remove('token')
        this.$cookies.remove('dadavid')
        window.sessionStorage.clear()
        window.localStorage.clear()
        this.$router.push('/Login')
      })
    },
    addClick() {
      // 修改密码
      this.$refs.addRef.validate((valid) => {
        if (!valid) return
        changePassword({ password: this.modifyPassword.password1 }).then(
          (res) => {
            if (res.data.code === '200') {
              this.$message.success('密码修改成功')
              this.query.password = this.modifyPassword.password1
              this.loadingBtn = false
              this.$cookies.remove('token')
              login(this.query)
                .then((res) => {
                  if (res.data.code === '200') {
                    this.$message.success('登入成功')
                    // 清除弱密码标识
                    this.$cookies.remove('setifuserweakPassword')
                    getRole().then((res) => {
                      // 判断是否为商家
                      if (res.data.data == true) {
                        this.$cookies.set('user', 'business')
                        this.$cookies.set('activePathypt', 'Merchanthomepage')
                        this.$router.push({ path: 'Merchanthomepage' })
                      } else {
                        if (this.$cookies.get('activePathypt')) {
                          this.$router.push({
                            path: this.$cookies.get('activePathypt'),
                          })
                        } else {
                          this.$cookies.remove('user')
                          this.$router.push({ path: 'Welcome' })
                        }
                      }
                    })
                  }
                })
                .finally((err) => {
                  this.loadingBtn = false
                })
            }
          }
        )
      })
    },
    handleFullScreen() {
      // 全屏事件
      let element = document.documentElement
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      this.fullscreen = !this.fullscreen
    },
  },
  created() {
    this.getCurrentSysAdmin()
  },
}
</script> 
<style scoped lang="scss">
.container {
  height: 100%;
}
::v-deep .el-header {
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    color: #fff;
    .togglebtn {
      font-size: 18px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .btn-rank {
      transform: rotate(45deg);
      margin: 0 10px;
      font-size: 24px;
      color: #fff;
    }
    .btnout {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        border-radius: 50%;
      }
    }
  }
}
.user {
  font-size: 23px;
  color: #999;
}
::v-deep .el-dropdown {
  color: #fff;
}
::v-deep .el-container {
  height: 100%;
}
::v-deep .el-header,
.el-aside {
  background-color: rgb(51, 55, 65);
}
::v-deep .el-menu {
  overflow-x: hidden;
  overflow-y: hidden;
  border-right: none;
}
::v-deep .el-scrollbar {
  background-color: rgb(51, 55, 65);
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
::v-deep .el-main {
  padding: 10px;
  min-width: calc(100vw - 240px);
}
::v-deep .el-table thead {
  font-weight: normal;
  color: #333;
}
::v-deep .el-table {
  color: #333;
}
::v-deep .el-table th {
  font-size: 13px;
}
::v-deep .el-table tr {
  font-size: 13px;
}
::v-deep .el-card {
  min-height: 600px;
}
::v-deep .el-card__body {
  min-height: 600px;
  box-sizing: border-box;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  .mainCenter {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px 100px 30px 100px;
    .title {
      p:nth-child(1) {
        color: red;
      }
      p:nth-child(2) {
        margin-top: 20px;
        font-weight: bolder;
      }
    }
    .dialog-footer {
      display: flex;
      justify-content: right;
      margin-top: 50px;
    }
  }
}
</style>
